import * as React from 'react';
import {graphql} from 'gatsby';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import { Col } from 'react-bootstrap';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import * as styles from './post-page-template.module.scss';

const BlogDetailTemplate = ({
  data: {
    allMdx: { edges },
  },
}) => {
  var post = edges[0].node;
  return (
    <Layout>
      <Seo title={post.frontmatter.title} />
      <Col lg={10} className={`py-5 mx-auto ${styles.post}`}>
        <MDXRenderer>{post.body}</MDXRenderer>
      </Col>
    </Layout>
  );
};

export const query = graphql`
  query postData($nodeId: String!) {
    allMdx(filter: { id: { eq: $nodeId } }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          slug
          body
          frontmatter {
            metadescription
            metakeywords
            title
          }
        }
      }
    }
  }
`;

export default BlogDetailTemplate;
